/* You can add global styles to this file, and also import other style files */

html, body {
    font-family: Inter, sans-serif !important;
    background-color: #050507;
    color: #F9F9F9 !important;
    width:100%;
    height:auto;
    font-weight: 300;
    overflow-x:hidden;
    line-height: 1.8rem;
}

.navbar-light .navbar-nav {
    z-index:10;
}

.navbar-light .navbar-nav .nav-link {
    color: #F9F9F9 !important;
    opacity:0.8;
    transition:0.5s;
    font-size:13px;
    font-weight:700;
    letter-spacing:1.25px;
    padding-right: .7rem;
    padding-left: .7rem;

    &:hover {
    opacity:1;
    transition:0.5s;
    }
}

.nav-link__cta {
    border:2px solid white;
    border-width:2px;
    padding-left:1.5rem !important;
    padding-right:1.5rem !important;
    padding-top:0.5rem;
    padding-bottom:0.5rem;
    border-radius:15px !important;
}

.glow-hero {
    width:20%;
    padding-bottom:20%;
    background-color:#00FF96;
    border-radius:100%;
    position:absolute;
    right:25%;
    top:20%;
    filter:blur(200px);
    z-index:0;
    opacity:0.7;
}

.glow-hero2 {
    width:20%;
    padding-bottom:20%;
    background-color:#0247BA;
    border-radius:100%;
    position:absolute;
    left:25%;
    top:20%;
    filter:blur(200px);
    z-index:0;
    opacity:0.7;
}

.glow-hero3 {
    width:33%;
    padding-bottom:33%;
    background-color:#000;
    border-radius:100%;
    position:absolute;
    margin:auto;
    left:0%;
    right:0;
    top:5%;
    filter:blur(150px);
    z-index:0;

}

.gradient-purple {
    width:10%;
    padding-bottom:10%;
    background-color:#C503F6;
    border-radius:100%;
    position:absolute;
    opacity:0.5;
    filter:blur(100px);
    transform: translateY(-30%);
    z-index:0;
}

.gradient-blue {
    width:10%;
    padding-bottom:10%;
    background-color:#024AB9;
    border-radius:100%;
    position:absolute;
    transform:translateX(-100%) translateY(-30%);
    opacity:0.5;
    filter:blur(100px);
    z-index:0;
}

.gradient-lightgreen {
    width:10%;
    padding-bottom:10%;
    background-color:#03f6d9;
    border-radius:100%;
    position:absolute;
    opacity:0.5;
    filter:blur(100px);
    transform: translateY(-30%);
    z-index:0;
}

.gradient-pink {
    width:10%;
    padding-bottom:10%;
    background-color:#b902a7;
    border-radius:100%;
    position:absolute;
    opacity:0.5;
    filter:blur(100px);
    transform:translateX(-120%) translateY(-30%);
    z-index:0;
}

.gradient-orange {
    width:10%;
    padding-bottom:10%;
    background-color:#F69503;
    border-radius:100%;
    position:absolute;
    opacity:0.5;
    filter:blur(100px);
    transform:translateX(100%) translateY(-30%);
    z-index:0;
}

.gradient-darkblue {
    width:10%;
    padding-bottom:10%;
    background-color:#024ab9;
    border-radius:100%;
    position:absolute;
    opacity:0.5;
    filter:blur(100px);
    transform:translateX(130%) translateY(-30%);
    z-index:0;
}

.gradient-green {
    width:10%;
    padding-bottom:10%;
    background-color:#00ff96;
    border-radius:100%;
    position:absolute;
    opacity:0.5;
    filter:blur(100px);
    transform:translateY(-30%);
    z-index:0;
}

.customer-glow1 {
    width:50%;
    padding-bottom:20%;
    background-color:#0247BA;
    border-radius:100%;
    position:absolute;
    left:-25%;
    filter:blur(250px);
    z-index:0;
    opacity:0.3;
}

.customer-glow2 {
    width:50%;
    padding-bottom:20%;
    background-color:#00FF96;
    border-radius:100%;
    position:absolute;
    right:-25%;
    filter:blur(250px);
    z-index:0;
    opacity:0.3;
}

.owl-carousel .center {
    opacity:1 !important;
}

.owl-item {
  opacity:0.2;
}

.owl-carousel .owl-item img {
    margin-left:auto;
    margin-right:auto;
}

.owl-carousel .center .date-box{
    border:2px solid rgba(255,255,255,0.6);
}

.platform {
    background-image:linear-gradient(to right, #C503F6, #024AB9) !important;
    border:none;
    outline: none !important;
    font-size:1.1rem;
    font-weight:600;
}

.deployments {
    background-image:linear-gradient(to right, #03F6D9, #B902A7) !important;
    border:none;
    outline: none !important;
    font-size:1.1rem;
    font-weight:600;
}

.inspace {
    background-image:linear-gradient(to right, #F69503, #9F02B9) !important;
    border:none;
    outline: none !important;
    font-size:1.1rem;
    font-weight:600;
}

.ground {
    background-image:linear-gradient(to right, #00FF96, #024AB9) !important;
    border:none;
    outline: none !important;
    font-size:1.1rem;
    font-weight:600;
}

 .disabled {
     opacity:0.3;
     transition:ease-in-out 0.5s;
 }

 .enabled {
     transition: ease-in-out 0.5s;
 }

 .cursor-pointer{
    cursor: pointer;
  }

  .letter-spacing {
    text-transform: uppercase;
    letter-spacing:2px;
    color:white;
    font-weight:400;
    font-size:14px;
}

.text-muted {
    color:#9396A2 !important;
    font-weight:400 !important;
}

::selection {
    background: #00FF96;
    color:black;
  }
::-moz-selection {
    background: #00FF96;
    color:black;
  }

.form-control {
    border-bottom:2px solid #00FF96 !important;
    border-left:none;
    border-right:none;
    border-top:none;
    background-color: transparent !important;
    color:white !important;
    border-radius:0px !important;
    padding:0 !important;
    padding-bottom:6px !important;
    border-image-slice:1;
    box-shadow:none !important;

}

.form-control::placeholder {
    color:white;
    opacity:0.6;
    padding:0 !important;
    padding-bottom:6px !important;
}

button {
    box-shadow:none !important;
}

.dropdown-menu {
    display:block;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(10px);

}

.dropdown-item {
    color:white;
}

.dropdown-item:focus, .dropdown-item:active, .dropdown-item:hover  {
    background-color:rgba(0,0,0,1);
    color:white;
    cursor:pointer;
}

.btn-secondary {
    background-color:#1f2122;
    border-color:#1f2122;
}

.spinner {
    animation: rotate 2s linear infinite;
    z-index: 2;
    width: 25px;
    height: 25px;
    
    & .path {
      stroke: hsl(255, 255, 255);
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }
    
  }
  
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
  